@if (form) {
  <div
    class="font-selector-cont"
    [class.column-flexed]="columnFlexed"
    [formGroup]="form"
  >
    <mat-form-field
      appearance="outline"
      class="flex-half"
      [ngClass]="{
        'ng-invalid mdc-text-field--invalid':
          (fontInputControl.touched || fontFormControl.touched) &&
          fontFormControl.invalid
      }"
    >
      <mat-label>{{ fontLabel }}</mat-label>
      <input
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="fontInputControl"
      />
      <i matSuffix class="fas fa-caret-down autocomplete-dropdown-icon"></i>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let font of filteredFonts | async"
        [value]="font.family"
        (click)="fontSelected($event, font)"
      >
        {{ font.family }}
      </mat-option>
    </mat-autocomplete>

    <mat-form-field class="flex-half" appearance="outline">
      <mat-label>{{ weightLabel | translate }}</mat-label>
      <mat-select [formControlName]="fontWeightFormControlName">
        <mat-option
          *ngFor="let fontWeight of fontVariants"
          [value]="fontWeight"
          (click)="fontWeightSelected(fontWeight)"
        >
          {{ getFontWeightDisplayName(fontWeight) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    @if (!withoutStyle) {
      <app-color-picker
        [form]="form"
        [colorFormControlName]="fontColorFormControlName"
      ></app-color-picker>
      <mat-form-field appearance="outline">
        <mat-label>{{ "APP.SIZE" | translate }}</mat-label>
        <input matInput type="number" disabled [value]="fontSize" />
      </mat-form-field>
    }
  </div>
}
