import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { AddressPickerDialog } from '@modules/shared/dialogs/address-picker/address-picker.dialog';
import { Address } from '@models/shared/address.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-address-picker',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule],
  templateUrl: './address-picker.component.html',
  styleUrl: './address-picker.component.scss',
})
export class AddressPickerComponent {
  addressControl = new FormControl('');
  private _address?: Address;

  dialogRef?: MatDialogRef<AddressPickerDialog>;

  constructor(private dialog: MatDialog) {}

  @Input() set address(address: Address | undefined) {
    if (!address) {
      this._address = address;
    }

    if (address && address.street) {
      this._address = new Address(address);
      this.addressControl.setValue(this._address.toString());
    }
  }

  get address(): Address | undefined {
    return this._address;
  }

  @Input() isEventEditPicker = false;

  @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();

  openPickerDialog() {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      return;
    }

    this.dialogRef = this.dialog.open(AddressPickerDialog, {
      maxWidth: '654px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        address: this.address,
        isEventEditPicker: this.isEventEditPicker,
      },
      panelClass: ['normal-dialog'],
    });
    this.dialogRef.afterClosed().subscribe((address: Address) => {
      if (address) {
        this.address = address;
        this.addressChange.emit(address);
        this.addressControl.setValue(address.toString());
      }
    });
  }

  clearAddress(e: Event): void {
    e.stopPropagation();
    this.address = undefined;
    this.addressControl.setValue('');
    this.addressChange.emit(undefined);
  }
}
