import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule, GoogleMap, MapGeocoder } from '@angular/google-maps';
import { NotificationService } from '@services/shared/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Address } from '@models/shared/address.model';

@Component({
  selector: 'app-address-picker',
  standalone: true,
  imports: [CommonModule, FormsModules, GoogleMapsModule, TranslateModule],
  providers: [NotificationService],
  templateUrl: './address-picker.dialog.html',
  styleUrl: './address-picker.dialog.scss',
})
export class AddressPickerDialog implements OnInit, AfterViewInit {
  form: FormGroup;

  mapZoom = 13;
  mapCenter: google.maps.LatLngLiteral = {
    lat: 52.52,
    lng: 13.4,
  };
  markerPosition = this.mapCenter;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
    draggableCursor: 'default',
  };

  markerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
    gmpDraggable: false,
  };

  loading = false;
  skipFindCoordinates = false;
  isEventEditPicker = false;

  @ViewChild(GoogleMap, { static: false }) map?: GoogleMap;
  @ViewChild('search', { static: false }) searchElementRef?: ElementRef;
  @ViewChild('streetSearch', { static: false })
  streetSearchElementRef?: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AddressPickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private geocoder: MapGeocoder,
    private notificationService: NotificationService,
    private ngZone: NgZone,
  ) {
    this.isEventEditPicker = this.data?.isEventEditPicker;

    this.form = new FormGroup({
      id: new FormControl(this.data?.address?.id || '', {
        updateOn: 'blur',
      }),
      name: new FormControl(this.data?.address?.name || '', {
        updateOn: 'blur',
      }),
      street: new FormControl(this.data?.address?.street || '', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      streetNumber: new FormControl(this.data?.address?.streetNumber || '', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      postalCode: new FormControl(this.data?.address?.postalCode || '', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      locality: new FormControl(this.data?.address?.locality || '', {
        updateOn: 'blur',
        validators: [Validators.required],
      }),
      country: new FormControl(this.data?.address?.country || '', {
        updateOn: 'blur',
      }),
      federalState: new FormControl(this.data?.address?.federalState || '', {
        updateOn: 'blur',
      }),
      placeId: new FormControl(this.data?.address?.placeId || '', {
        updateOn: 'blur',
      }),
    });

    this.form.valueChanges.subscribe((value) => {
      if (this.form.valid && !this.skipFindCoordinates) {
        let address = `${value.street} ${value.streetNumber}, ${value.postalCode} ${value.locality}`;
        if (value.federalState) {
          address += `, ${value.federalState}`;
        }
        if (value.country) {
          address += `, ${value.country}`;
        }
        this.findCoordinates(address);
      }
      this.skipFindCoordinates = false;
    });
  }

  ngOnInit() {
    if (this.data?.address) {
      this.markerPosition = {
        lat: this.data.address.lat,
        lng: this.data.address.lng,
      };
      this.mapCenter = this.markerPosition;
      this.mapZoom = 17;
    } else {
      this.getCurrentLocation();
    }
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef?.nativeElement,
      {
        types: ['establishment'],
      },
    );

    const streetAutocomplete = new google.maps.places.Autocomplete(
      this.streetSearchElementRef?.nativeElement,
      {
        types: ['address'],
      },
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.form.patchValue({
          name: place.name,
          street: this.getStreet(place),
          streetNumber: this.getStreetNumber(place),
          postalCode: this.getPostalCode(place),
          locality: this.getLocality(place),
          country: this.getCountry(place),
          federalState: this.getState(place),
          placeId: place.place_id,
        });

        this.findCoordinates(
          this.form.value.street + ' ' + this.form.value.streetNumber,
        );
      });
    });

    streetAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult =
          streetAutocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.form.patchValue({
          street: this.getStreet(place),
          streetNumber: this.getStreetNumber(place),
          postalCode: this.getPostalCode(place),
          locality: this.getLocality(place),
          country: this.getCountry(place),
          federalState: this.getState(place),
          placeId: place.place_id,
        });

        this.findCoordinates(
          this.form.value.street + ' ' + this.form.value.streetNumber,
        );
      });
    });
  }

  private getStreet(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('route'),
    );
    return component ? component.long_name : '';
  }

  private getStreetNumber(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('street_number'),
    );
    return component ? component.long_name : '';
  }

  private getState(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('administrative_area_level_1'),
    );
    return component ? component.long_name : '';
  }

  private getCountry(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('country'),
    );
    return component ? component.long_name : '';
  }

  private getPostalCode(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('postal_code'),
    );
    return component ? component.long_name : '';
  }

  private getLocality(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('locality'),
    );
    return component ? component.long_name : '';
  }

  cancel() {
    this.dialogRef.close();
  }

  ok() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;
    const address = new Address({
      id: value.id,
      street: value.street,
      streetNumber: value.streetNumber,
      postalCode: value.postalCode,
      locality: value.locality,
      country: value.country,
      federalState: value.federalState,
      lat: this.markerPosition.lat,
      lng: this.markerPosition.lng,
      placeId: value.placeId,
    });

    if (this.isEventEditPicker) {
      address.name = value.name || '';
    }

    this.dialogRef.close(address);
  }

  mapClick(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.form.reset();
      this.markerPosition = event.latLng.toJSON();
      this.getAddress(this.markerPosition.lat, this.markerPosition.lng);
    }
  }

  getAddress(lat: number, lng: number) {
    this.geocoder
      .geocode({ location: { lat: lat, lng: lng } })
      .subscribe(({ results }) => {
        for (const result of results) {
          if (result.types.includes('street_address')) {
            result.address_components.forEach((addressComponent) => {
              if (addressComponent.types.includes('street_number')) {
                this.form.controls['streetNumber'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              } else if (addressComponent.types.includes('route')) {
                this.form.controls['street'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              } else if (addressComponent.types.includes('locality')) {
                this.form.controls['locality'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              } else if (
                addressComponent.types.includes('administrative_area_level_1')
              ) {
                this.form.controls['federalState'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              } else if (addressComponent.types.includes('country')) {
                this.form.controls['country'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              } else if (addressComponent.types.includes('postal_code')) {
                this.form.controls['postalCode'].patchValue(
                  addressComponent.long_name,
                  { emitEvent: false, onlySelf: true },
                );
              }
            });

            this.form.controls['placeId'].patchValue(result.place_id, {
              emitEvent: false,
              onlySelf: true,
            });

            this.skipFindCoordinates = true;
            this.form.updateValueAndValidity();

            break;
          }
        }
      });
  }

  findCoordinates(address: string) {
    this.geocoder.geocode({ address: address }).subscribe(({ results }) => {
      for (const result of results) {
        if (result.types.includes('street_address')) {
          this.markerPosition = result.geometry.location.toJSON();
          this.mapCenter = this.markerPosition;
          this.mapZoom = 17;
          break;
        }
      }
    });
  }

  getCurrentLocation() {
    this.loading = true;

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;

        const point: google.maps.LatLngLiteral = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.mapCenter = point;
        this.markerPosition = this.mapCenter;
        this.getAddress(this.markerPosition.lat, this.markerPosition.lng);
        if (this.map) {
          this.map.panTo(point);
        }

        this.markerOptions = {
          gmpDraggable: false,
        };
      },
      (error) => {
        this.loading = false;

        if (error.PERMISSION_DENIED) {
          this.notificationService.error(
            "Couldn't get your location",
            'Permission denied',
          );
        } else if (error.POSITION_UNAVAILABLE) {
          this.notificationService.error(
            "Couldn't get your location",
            'Position unavailable',
          );
        } else if (error.TIMEOUT) {
          this.notificationService.error(
            "Couldn't get your location",
            'Timed out',
          );
        } else {
          this.notificationService.error(error.message, `Error: ${error.code}`);
        }
      },
      { enableHighAccuracy: true },
    );
  }
}
