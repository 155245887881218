<div class="template-asset-picker">
  <app-search-box
    [placeholder]="searchBoxPlaceholder"
    [debounceTime]="250"
    (valueChanged)="onSearchTermChange($event)"
  ></app-search-box>

  <div class="selection-list" [class.shortview-container]="isShortView">
    @for (asset of assets; track asset.id) {
      <app-template-asset-card
        [asset]="asset"
        [size]="110"
        [selectMode]="true"
        [selected]="selectedAssetId === asset.id"
        (assetChosen)="onAssetChosen($event)"
      ></app-template-asset-card>
    }
  </div>
</div>
