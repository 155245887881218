import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { FontService } from '@services/shared/font.service';
import { GoogleFont } from '@models/shared/google-font.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-font-selector',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule, ColorPickerComponent],
  templateUrl: './font-selector.component.html',
  styleUrl: './font-selector.component.scss',
})
export class FontSelectorComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() fontLabel: string = '';
  @Input() weightLabel: string = 'APP.TITLE_FONT_WEIGHT';
  @Input() fontFormControlName!: string;
  @Input() fontColorFormControlName!: string;
  @Input() fontWeightFormControlName!: string;
  @Input() fontSize: number = 24;
  @Input() columnFlexed?: boolean = false;
  @Input() withoutStyle?: boolean = false;

  @Output() fontChanged = new EventEmitter();

  fonts: GoogleFont[] = [];
  fontVariants: number[] = [];

  fontInputControl = new FormControl('');
  filteredFonts: Observable<GoogleFont[]> = new Observable();

  constructor(private fontService: FontService) {}

  ngOnInit() {
    this.fontService.googleFonts.subscribe((fonts) => {
      this.fonts = fonts;

      this.filteredFonts = this.fontInputControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || '')),
      );

      this.setSelectedFont();
    });
  }

  fontWeightSelected(fontWeight: number) {
    if (this.fontChanged && this.withoutStyle) {
      this.fontChanged.emit({
        [this.fontWeightFormControlName]: fontWeight,
      });
    }
  }
  setSelectedFont() {
    if (this.form && this.fontFormControl?.value) {
      const selectedFont = this.fonts.find(
        (font) => font.family === this.fontFormControl.value,
      );
      if (selectedFont) {
        this.fontInputControl.setValue(this.fontFormControl.value);
        this.fontVariants = selectedFont.variants
          .filter((variant) => !variant.includes('italic'))
          .map((variant) => (variant === 'regular' ? 400 : +variant));
      }
    }
  }

  fontSelected(event: any, font: GoogleFont) {
    event.stopPropagation();
    this.form.controls[this.fontFormControlName].setValue(font.family);
    this.fontVariants = font.variants
      .filter((variant) => !variant.includes('italic'))
      .map((variant) => (variant === 'regular' ? 400 : +variant));
    this.form.controls[this.fontWeightFormControlName].setValue('');
    if (this.fontChanged && this.withoutStyle) {
      this.fontChanged.emit({
        [this.fontFormControlName]: font.family,
      });
    }
  }

  private _filter(value: string): GoogleFont[] {
    const filterValue = value.toLowerCase();

    return this.fonts.filter((font) =>
      font.family.toLowerCase().includes(filterValue),
    );
  }

  getFontWeightDisplayName(fontWeight: number): string {
    return `APP.FONT_WEIGHT.${fontWeight}`;
  }

  get fontFormControl(): AbstractControl {
    return this.form.controls[this.fontFormControlName];
  }
}
