import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventTemplateService } from '@services/events/event-template.service';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { TemplateAssetCardComponent } from '../template-asset-card/template-asset-card.component';

@Component({
  selector: 'app-template-asset-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    SearchBoxComponent,
    TemplateAssetCardComponent,
  ],
  providers: [EventTemplateService],
  templateUrl: './template-asset-picker.component.html',
  styleUrl: './template-asset-picker.component.scss',
})
export class TemplateAssetPickerComponent implements OnInit, AfterViewInit {
  EventTemplateAssetType = EventTemplateAssetType;
  assets?: EventTemplateAsset[];
  searchTerm?: string;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  @Input() isFocused?: Observable<boolean>;
  @Input() categories?: EventCategory[];
  @Input() activeCategory?: EventCategory;
  @Input() type = EventTemplateAssetType.BACKGROUND;
  @Input() selectedAssetId?: number;
  @Input() isShortView?: boolean;

  @Output() assetChosen: EventEmitter<EventTemplateAsset | undefined> =
    new EventEmitter();
  @ViewChild(SearchBoxComponent) searchBox?: SearchBoxComponent;
  constructor(private eventTemplateService: EventTemplateService) {}

  ngOnInit(): void {
    this.onSearchTermChange('');
  }

  ngAfterViewInit(): void {
    this.isFocused
      ?.pipe(takeUntil(this._onDestroy))
      .subscribe((isFocused: boolean) => {
        if (isFocused) {
          setTimeout(() => this.searchBox?.focus());
        }
      });
  }

  onSearchTermChange(searchTerm: string) {
    this.searchTerm = searchTerm;

    this.eventTemplateService.getAssets(this.type, searchTerm).subscribe({
      next: (assets) => {
        this.assets = assets;
      },
    });
  }

  onAssetChosen(asset?: EventTemplateAsset) {
    this.assetChosen.emit(asset);
  }

  getAssetsForCategory(categoryId?: number): EventTemplateAsset[] | undefined {
    if (categoryId) {
      return this.assets?.filter(
        (asset) =>
          asset.categories
            ?.map((category) => category.id)
            .includes(categoryId) ||
          asset.categories
            ?.map((category) => category.parentCategoryId)
            .includes(categoryId),
      );
    }

    return this.assets;
  }

  get searchBoxPlaceholder(): string {
    switch (this.type) {
      case EventTemplateAssetType.BACKGROUND:
        return 'APP.CHANGE_EFFECTS.SEARCH_BACKGROUNDS';
      case EventTemplateAssetType.KEY_VISUAL:
        return 'APP.CHANGE_EFFECTS.SEARCH_KEY_VISUALS';
    }
    return 'APP.CHANGE_EFFECTS.SEARCH_OVERLAYS';
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
