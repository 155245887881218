import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalSidenavUserIconComponent } from '../internal-sidenav-user-icon/internal-sidenav-user-icon.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MenuRoute } from '@models/sidenav/menu-route.model';
import { SidenavService } from '@services/shared/sidenav.service';
import { environment as env } from '@environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-internal-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    InternalSidenavUserIconComponent,
  ],
  providers: [SidenavService],
  templateUrl: './internal-sidenav.component.html',
  styleUrl: './internal-sidenav.component.scss',
})
export class InternalSidenavComponent {
  private _routes: MenuRoute[] = [];

  @Input() set routes(routes: MenuRoute[]) {
    if (!!routes && routes.length > 0) {
      this._routes = routes;
      this.checkRouteItemOpened();
    }
  }

  get routes(): MenuRoute[] {
    return this._routes;
  }

  @Input() isOverMode?: boolean;

  @Input() isCustomerView?: boolean;

  @Output() openReferralInviteDialog = new EventEmitter<void>();

  activeRouteUrl?: string;
  shouldExpandMenuContainer?: boolean;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
  ) {
    this.trackRouteChanges();
  }

  private trackRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRouteUrl = window.location.pathname;
        this.checkRouteItemOpened();
      }
    });
  }

  isRouteActive(route: string): boolean {
    return route === this.activeRouteUrl;
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  onRouteClick() {
    if (this.isOverMode) {
      this.closeSidenav();
    }
  }

  onParentRouteClick(routeItem: MenuRoute) {
    routeItem.opened = !routeItem.opened;
  }

  closeSidenav() {
    const sidenavCloseRes = this.sidenavService.close();
    sidenavCloseRes?.then();
  }

  checkRouteItemOpened() {
    for (const route of this.routes) {
      if (route.children) {
        route.opened = this.isRouteItemOpened(route.children);
      }
    }
  }

  isRouteItemOpened(routes: MenuRoute[]): boolean {
    for (const route of routes) {
      if (this.activeRouteUrl === route.route) {
        return true;
      }
    }
    return false;
  }

  onOpenReferralInviteDialog() {
    this.openReferralInviteDialog.emit();
  }

  protected env = env;
}
